<template>
    <ModelPartForm 
    v-if="loaded==true"

    :glass_id="glass_id" 
    :readOnly="false" 
    :model_part="model_part"
     />
</template>

<script>
import {BModal, BButton, VBModal, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'

import ModelPartForm from './ModelPartForm.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,

    ModelPartForm
},
  data() {
    return {
      glass_id:null,
      model_part:null,
      loaded:false,

    }
  },
  watch:{
    
  },
  methods:{
    async getModelPart()
    {
      this.model_part=await APIRequest.detail('model-parts',this.$route.params.id)
      this.glass_id=this.model_part.glass.id
      this.loaded=true
    }
  },
  async mounted()
  {
    this.getModelPart()
  
  }
}
</script>
